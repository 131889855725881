// Define the delay between each row animation
$row-delay: 0.1s;

// Define the animation keyframes
@keyframes row-fade-in {
	from {
		opacity: 0;
		transform: translateX(-10%);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

// Set the initial opacity of each row to 0
.row-animation {
	opacity: 0;
	animation-delay: 1000;
}

// Generate the CSS rules for each row with staggered delay
@for $i from 1 through 32 {
	.row-animation:nth-child(#{$i}) {
		animation: row-fade-in 0.5s ease-in-out #{($i - 1) * $row-delay} forwards;
	}
}
